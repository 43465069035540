import * as React from 'react';
import { Box, Text, Flex, keyframes, CloseButton } from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';

type FlyOutProps = {
  top: string;
  mainBodyWidth: string;
  mainBodyHeight: string;
  buttonWidth: string;
  buttonHeight: string;
  buttonLabel: string;
  children: React.ReactFragment;
};

/**
 * FlyOut is a responsive component that provides an overlay slide-out
 * (or "flyout") panel functionality. On desktop view, it appears from
 * the right side of the screen, and on mobile view, it occupies the full viewport
 * height. It includes a button to trigger the flyout's opening and closing.
 * When the flyout is open, the background darkens to emphasize focus.
 * The flyout content is customizable via the 'children' prop.
 */
const FlyOut = ({
  top,
  mainBodyWidth,
  mainBodyHeight,
  buttonWidth,
  buttonHeight,
  buttonLabel,
  children,
}: FlyOutProps) => {
  // Mobile detection from: https://stackoverflow.com/questions/39435395/reactjs-how-to-determine-if-the-application-is-being-viewed-on-mobile-or-deskto
  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const bodyWidth = isMobile ? `${width}px` : mainBodyWidth;
  const bodyHeight = isMobile ? '100vh' : mainBodyHeight;

  const animationKeyframes = keyframes`
  0% { right: -${bodyWidth} }
  100% { right: 0% }
`;
  const animationKeyframes2 = keyframes`
  0% { right: 0px; }
  100% { right: -${bodyWidth} }
`;
  const animationIn = `${animationKeyframes} 0.5s ease-in-out 0s 1 normal forwards`;
  const animationOut = `${animationKeyframes2} 0.5s ease-in-out 0s 1 normal forwards`;

  const [isOpen, setIsOpen] = React.useState(false);
  const [flyoutAnimation, setFlyoutAnimation] = React.useState('');

  const toggleFlyout = async (forceClose?: boolean) => {
    // If forceClose is true, the function will close the flyout regardless of its current state. If it's undefined, the function will toggle the state of the flyout.
    const nextIsOpen = forceClose !== undefined ? !forceClose : !isOpen;

    setFlyoutAnimation(nextIsOpen ? animationIn : animationOut);
    setIsOpen(nextIsOpen);

    if (!nextIsOpen) {
      // Need to take away the animation once it is completed, otherwise the collapsed
      // flyout will behave weird when the browser width is changed.
      await new Promise(() =>
        setTimeout(() => {
          setFlyoutAnimation('');
        }, 500),
      );
    }
  };

  return (
    <React.Fragment>
      {/* darken the background when Flyout is opened */}
      {isOpen && (
        <Box
          position="fixed"
          w="full"
          h="full"
          top="0"
          left="0"
          bg="rgba(0,0,0,0.4)"
          onClick={() => toggleFlyout(true)}
          zIndex="popover"
        />
      )}

      {/* This is the flyover itself. Note that zindex is set to popover to be over the
      modal background . Set pointer events to none so the empty part of the flyover box
       pass the click to the modal overlay but the filled flyout area and the button
        accept clicks.*/}
      <Flex
        position="fixed"
        right={`-${bodyWidth}`}
        top={isMobile ? '0' : top}
        height={isMobile ? '100%' : undefined}
        animation={flyoutAnimation}
        zIndex="popover"
        flexDirection="row"
        pointerEvents="none">
        <Box
          position="relative"
          width={isMobile ? '48px' : buttonWidth}
          height={isMobile ? '48px' : buttonHeight}
          background="#0D90CE"
          boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.08)"
          borderRadius="6px 0px 0px 6px"
          marginTop={isMobile ? '200px' : '25px'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          onClick={() => toggleFlyout()}
          pointerEvents="auto">
          <PhoneIcon color="white" boxSize="24px" />
          {!isMobile ? (
            <Text mt="2" color="white" fontSize="sm" fontFamily="Jost">
              {buttonLabel}
            </Text>
          ) : undefined}
        </Box>
        <Box
          width={bodyWidth}
          height={bodyHeight}
          background="#FFFFFF"
          boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.08)"
          borderRadius="8px 0px 0px 8px"
          padding="32px 25px"
          pointerEvents="auto">
          <CloseButton
            float="right"
            width="24px"
            size="lg"
            onClick={() => toggleFlyout(true)}></CloseButton>
          {children}
        </Box>
      </Flex>
    </React.Fragment>
  );
};

export default FlyOut;
