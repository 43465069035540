import { FC, useState, useRef, useEffect } from 'react';
import { RadioGroup, Radio, Input, Flex, Box, Text, LinkBox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useBookingForm } from 'context/formContext/formContext';
import torchImg from '../../public/torch.png';

interface ClientReferralOption {
  label: string;
  value: string;
}

export interface ClientReferralFormValues {
  email?: string;
}

export const ClientReferral: FC = () => {
  const { aboutForm } = useBookingForm();
  const otherInputRef = useRef<HTMLInputElement>(null);
  const [referrer, setReferrer] = useState('');
  const [otherReferrer, setOtherRefferer] = useState('');
  const { t } = useTranslation(['success']);

  const email = aboutForm.watch('email');

  const options: ClientReferralOption[] = [
    {
      label: t('success:referrers.taxAdvisor'),
      value: 'taxAdvisor',
    },
    {
      label: t('success:referrers.onlineMerchant'),
      value: 'onlineMerchant',
    },
    {
      label: t('success:referrers.internetResearch'),
      value: 'internetResearch',
    },
    {
      label: t('success:referrers.event'),
      value: 'event',
    },
    {
      label: t('success:referrers.socialMedia'),
      value: 'socialMedia',
    },
    {
      label: t('success:referrers.podcast'),
      value: 'podcast',
    },
    {
      label: t('success:referrers.taxdooConnector'),
      value: 'taxdooConnector',
    },
  ];

  useEffect(() => {
    if (referrer === 'other') {
      otherInputRef.current?.focus();
    }
  }, [referrer]);

  const hubSpotCallback = () => {
    let referrerName = '';

    if (!referrer) {
      return;
    }

    if (referrer === 'other') {
      referrerName = otherReferrer;
    } else {
      referrerName = options.filter((option) => option.value === referrer)[0].label;
    }

    window?.dataLayer?.push({
      event: 'booking_lead_source',
      lead_source: referrerName,
    });
  };

  return (
    <Flex direction={'column'}>
      <Flex
        mb={{ base: '64px', md: '64px' }}
        alignItems={'center'}
        direction={{ base: 'column-reverse', md: 'row' }}>
        <Box pr={{ base: 0, md: '60px', lg: '120px' }}>
          <Text
            color={'black'}
            fontSize={{ base: '32px', md: '32px' }}
            fontWeight={'semibold'}
            lineHeight={{ base: '28px', md: '28px' }}
            mb={'16px'}>
            {t('success:headline')}
          </Text>
          <Text
            color={'black'}
            fontSize={{ base: '18px', md: '18px' }}
            lineHeight={{ base: '28px', md: '28px' }}
            dangerouslySetInnerHTML={{
              __html: t('success:description', {
                email: `<strong>${email}</strong>`,
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Box>
        <Box
          px={{ base: '40px', md: 0 }}
          mb={{ base: '64px', md: 0 }}
          maxWidth={{ base: '100%', md: '243px' }}>
          <img alt={t('success:accessibility.torchImageAlt')} src={torchImg.src} />
        </Box>
      </Flex>
      <Flex direction={'column'}>
        <Text
          fontSize={{ base: '16px', md: '16px' }}
          lineHeight={{ base: '20px', md: '20px' }}
          fontWeight={'semibold'}
          color={'black'}
          mb={{ base: '16px', md: '16px' }}>
          {t('success:howTo')}
        </Text>
        <RadioGroup
          alignItems={'flex-start'}
          flexDirection={'column'}
          mb={'64px'}
          as={Flex}
          onChange={setReferrer}>
          {options.map((option) => {
            return (
              <Radio mb={'16px'} value={option.value} key={option.value}>
                <Text
                  color={'black'}
                  fontSize={{ base: '18px', md: '18px' }}
                  lineHeight={{ base: '28px', md: '28px' }}>
                  {option.label}
                </Text>
              </Radio>
            );
          })}
          <Radio value={'other'}>
            <Input
              ref={otherInputRef}
              type={'text'}
              onClick={() => otherInputRef.current?.focus()}
              onChange={({ currentTarget }) => setOtherRefferer(currentTarget.value)}
              placeholder={t('success:referrers.others')}
            />
          </Radio>
        </RadioGroup>
        <LinkBox
          alignSelf={'flex-start'}
          border={'2px solid'}
          borderColor={'taxdooBlue.600'}
          borderRadius={'lg'}
          px={'24px'}
          py={'12px'}>
          <a onClick={hubSpotCallback} href={t('success:backToTaxdoo.link')}>
            <Text color={'taxdooBlue.600'} fontSize={'lg'} fontWeight={'semibold'}>
              {t('success:backToTaxdoo.label')}
            </Text>
          </a>
        </LinkBox>
      </Flex>
    </Flex>
  );
};
