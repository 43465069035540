import { Box, Text, Heading, Flex, Radio, useRadioGroup } from '@chakra-ui/react';
import { RadioCard } from '@components/RadioCard/RadioCard';
import { SubscriptionFormValues } from '@components/SubscriptionForm/SubscriptionForm';
import { useBookingForm } from 'context/formContext/formContext';
import React, { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type SelectGmvProps = {
  control: Control<SubscriptionFormValues, 'gmv'>;
  children?: React.ReactNode;
  name: keyof SubscriptionFormValues;
  selectedValue?: string;
};
export const SelectGmv: FC<SelectGmvProps> = ({ control, name, selectedValue }) => {
  const { subscribeForm } = useBookingForm();
  const { setValue } = subscribeForm;
  const { field } = useController({
    name,
    control,
  });

  const { t } = useTranslation(['package']);
  const upTo = t('package:gmv.price.upTo');
  const moreThan = t('package:gmv.price.moreThan');
  const gmvRange = [
    {
      prefix: upTo,
      label: '25.000 €',
      value: 25000,
    },
    {
      prefix: upTo,
      label: '50.000 €',
      value: 50000,
    },
    {
      prefix: upTo,
      label: '100.000 €',
      value: 100000,
    },
    {
      prefix: upTo,
      label: '200.000 €',
      value: 200000,
    },
    {
      prefix: upTo,
      label: '400.000 €',
      value: 400000,
    },
    {
      prefix: moreThan,
      label: '400.000 €',
      value: 400001,
    },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...field,
  });
  const group = getRootProps();

  const gmvRenderer = (
    gmvs: {
      prefix: string;
      label: string;
      value: number;
    }[],
  ) => {
    return gmvs.map(({ prefix, label, value }) => {
      const isChecked = value === Number(selectedValue);
      const radio = getRadioProps({ value });

      return (
        <RadioCard
          data-testid={`gmv-${value}-radiocard`}
          onClick={() => setValue('package', undefined, { shouldValidate: true })}
          key={`${label}-${value}`}
          radio={radio}
          borderRadius="lg"
          boxShadow={isChecked ? 'none' : 'md'}
          bgColor={isChecked ? 'taxdooBlue.50' : 'white'}
          _hover={{
            boxShadow: isChecked ? 'none' : 'lg',
          }}
          px={8}
          py={4}>
          <Flex justifyContent={'space-between'} alignItems="center" gap={4}>
            <Flex gap={2} alignItems={'center'}>
              <Text fontWeight={'medium'} fontSize={'lg'}>
                {prefix}
              </Text>
              <Text fontWeight={'semibold'} fontSize={'xl'}>
                {label}
              </Text>
            </Flex>
            <Radio {...radio} value={value} size={'lg'} isChecked={isChecked} />
          </Flex>
        </RadioCard>
      );
    });
  };

  return (
    <Box mb={32}>
      <Heading fontSize={'44px'} my={16}>
        {t('package:gmv.headline')}
      </Heading>
      <Heading fontSize={'4xl'}>{t('package:gmv.subHeadline')}</Heading>
      <Text fontSize={'2xl'} mt={8} mb={12}>
        {t('package:gmv.text')}
      </Text>

      <Box {...group} {...field}>
        <Flex gap={6} flex={1} direction={'column'} wrap="wrap" maxH={['100%', '300px']}>
          {gmvRenderer(gmvRange)}
        </Flex>
      </Box>
    </Box>
  );
};
