import { forwardRef } from 'react';
import { useRadio, Box, UseRadioProps, BoxProps } from '@chakra-ui/react';

type RadioCardProps = {
  children: React.ReactNode;
  radio: UseRadioProps;
  isDisabled?: boolean;
} & Omit<BoxProps, 'onChange'>;

export const RadioCard = forwardRef<HTMLInputElement, RadioCardProps>(
  ({ children, radio, isDisabled, ...props }, ref) => {
    const { getInputProps, getCheckboxProps } = useRadio(radio);

    const input = getInputProps({ ref });
    const checkbox = getCheckboxProps();

    return (
      <Box as="label" flex="1">
        <input {...input} disabled={isDisabled} />
        <Box {...checkbox} cursor="pointer" {...props}>
          {children}
        </Box>
      </Box>
    );
  },
);

RadioCard.displayName = 'RadioCard';
