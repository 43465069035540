import '../services/i18n';

import type { NextPage } from 'next';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

import { Footer } from '@components/Footer';
import { Navbar } from '@components/Navbar/Navbar';
import { PackageFeatures } from '@components/PackageFeatures';
import { ProgressBar } from '@components/ProgressBar';
import { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { SubscriptionForm } from '@components/SubscriptionForm/SubscriptionForm';
import { AboutForm } from '@components/AboutForm';
import { ClientReferral } from '@components/ClientReferral';
import { AddonsForm } from '@components/AddonsForm/AddonsForm';
import { usePage } from 'context/pageContext/pageContext';
import { PlaceOrder } from '@components/PlaceOrder';
import { useBookingForm } from 'context/formContext/formContext';
import FlyoutMenu from '@components/FlyoutMenu/';

const TAG_MANAGER_ARGS = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID as string,
};

const Home: NextPage = () => {
  const { t } = useTranslation(['home', 'meta', 'header', 'progressBar']);
  const [hydrated, setHydrated] = useState(false);
  const { currentPage, setPage } = usePage();
  const forms = useBookingForm();

  useEffect(() => {
    TagManager.initialize(TAG_MANAGER_ARGS);
    setHydrated(true);
  }, []);
  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null;
  }

  const steps = [
    {
      label: t('progressBar:step1.text'),
      form: forms.subscribeForm,
    },
    {
      label: t('progressBar:step2.text'),
      form: forms.addonsForm,
    },
    {
      label: t('progressBar:step3.text'),
      form: forms.aboutForm,
    },
    {
      label: t('progressBar:step4.text'),
      form: forms.orderForm,
    },
  ];

  return (
    <Box>
      <Head>
        <title>{t('header:title.text')}</title>
        <meta name="description" content={t('meta:description.content')} />
        <link rel="icon" href="./favicon.ico" />
      </Head>

      <FlyoutMenu />
      <Box w="full" display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Navbar />

        {/* container div for the forms */}
        <Flex
          direction={'column'}
          mt={36}
          maxW="container.xl"
          w="full"
          px={{ base: 6, md: '128px' }}>
          {currentPage <= 3 && (
            <ProgressBar steps={steps} currentStep={currentPage} onStepChange={setPage} />
          )}

          {/*
          show relevant form based on the current step.
          the form state slice and the function to update state are passed to form.
          */}
          {currentPage === 0 && <SubscriptionForm />}
          {currentPage === 1 && <AddonsForm />}
          {currentPage === 2 && <AboutForm />}
          {currentPage === 3 && <PlaceOrder />}
          {currentPage === 4 && <ClientReferral />}
        </Flex>

        {currentPage === 0 && <PackageFeatures />}
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
