import {
  Box,
  Text,
  Heading,
  useRadioGroup,
  Radio,
  Stack,
  useBreakpointValue,
  Flex,
} from '@chakra-ui/react';
import { RadioCard } from '@components/RadioCard/RadioCard';
import { SubscriptionFormValues } from '@components/SubscriptionForm/SubscriptionForm';
import { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { packageGmvMap, PackagesType } from 'utils/constants/packageGmvMap';
import { Package } from './Package';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { useBookingForm } from 'context/formContext/formContext';
import { BasePackage } from '@types';

type SelectPackageProps = {
  control: Control<SubscriptionFormValues, 'gmv'>;
  children?: React.ReactNode;
  name: keyof SubscriptionFormValues;
  selectedValue?: string;
  selectedGmv?: string;
};

type GetPkgStatusParams = {
  plan: {
    name: string;
    value: string;
  };
  selectedGmv?: string;
  selectedValue?: string;
};

const getPackageStatus = ({ plan, selectedGmv, selectedValue }: GetPkgStatusParams) => {
  const isChecked = plan.value === selectedValue;
  let isDisabled = false;
  if (!selectedGmv) {
    isDisabled = true;
  }

  return {
    isDisabled,
    isChecked,
  };
};

export const SelectPackage: FC<SelectPackageProps> = ({
  control,
  name,
  selectedValue,
  selectedGmv,
}) => {
  const { addonsForm, subscribeForm } = useBookingForm();

  const keenSliderClass = useBreakpointValue({
    base: 'keen-slider',
    md: 'no__slide',
    lg: 'no__slide',
    xl: 'no__slide',
  });
  const sliderClass = useBreakpointValue({
    base: 'keen-slider__slide',
    md: 'no__slide',
    lg: 'no__slide',
    xl: 'no__slide',
  });

  const { field } = useController({
    name,
    control,
  });
  const { t } = useTranslation(['package', 'general']);

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...field,
  });

  const group = getRootProps();
  const subscriptionPlans = t('package:subscription.plans', { returnObjects: true });

  const isActive = keenSliderClass == 'keen-slider';

  const onCardSelected = (selected: BasePackage) => {
    // Reset selected addons
    addonsForm.reset();

    selectedValue = selected;

    subscribeForm.setValue('package', selected, {
      shouldValidate: true,
    });
  };

  return (
    <Box>
      <Heading fontSize={'4xl'} mb={8}>
        {t('package:subscription.subHeadline')}
      </Heading>
      <Text fontSize={'2xl'} mb={12}>
        {t('package:subscription.text')}
      </Text>

      <KeenSliderWrapper isActive={isActive}>
        <Stack
          direction={['row', 'row', 'column', 'row']}
          w={['20em', 'full']}
          mb={32}
          spacing={{ base: 0, md: '24px' }}
          {...group}
          {...field}>
          {subscriptionPlans.map((plan) => {
            const radio = getRadioProps({ value: plan.value });
            const { isDisabled, isChecked } = getPackageStatus({
              plan,
              selectedGmv,
              selectedValue,
            });

            let ctaBtnText = t('general:choose');
            if (isDisabled) {
              ctaBtnText = t('general:notAvailable');
            }
            if (isChecked) {
              ctaBtnText = t('general:selected');
            }

            let price = '__ €';
            const mappedPrice = packageGmvMap[plan.value as PackagesType].unitPrice.get(
              String(selectedGmv),
            );
            if (Number(mappedPrice) > 0) {
              price = `${mappedPrice} €`;
            }

            let priceDetail = mappedPrice ? plan.billedAt.forLowerGmv : '';
            if (Number(selectedGmv) > 400000) {
              priceDetail = plan.billedAt.forHigherGmv || '';
            }

            const pkg = {
              name: plan.name,
              value: plan.value,
              price,
              priceDetail,
              features: plan.features,
              ctaBtnText,
              isDisabled,
              isChecked,
            };

            return (
              <Flex w={'100%'} className={sliderClass} key={plan.name}>
                <RadioCard
                  data-testid={`package-${plan.value}-radiocard`}
                  onClick={() => !isDisabled && onCardSelected(plan.value as BasePackage)}
                  radio={radio}
                  isDisabled={isDisabled}
                  display={'flex'}
                  height={'100%'}
                  padding={{ base: '0px 10px 30px 10px', md: 0 }}>
                  <Package {...pkg}>
                    <Radio
                      {...radio}
                      value={plan.value}
                      size={'lg'}
                      isChecked={plan.value === selectedValue}
                      isDisabled={isDisabled}
                    />
                  </Package>
                </RadioCard>
              </Flex>
            );
          })}
        </Stack>
      </KeenSliderWrapper>
    </Box>
  );
};

function KeenSliderWrapper(props: { isActive: boolean; children: JSX.Element }) {
  const [ref] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 1.1,
      spacing: 14,
    },
    slideChanged() {
      console.log('slide changed');
    },
  });

  if (props.isActive) {
    return (
      <div ref={ref} className="keen-slider">
        {props.children}
      </div>
    );
  }
  return <div>{props.children}</div>;
}
