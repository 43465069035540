import { useState, useEffect } from 'react';
import { Heading, HStack, Show, Center } from '@chakra-ui/react';
import { Logo } from '@Icons';
import { LanguageSwitch } from './LanguageSwitch';
import { useTranslation } from 'react-i18next';

export const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [oldScrollValue, setOldScrollValue] = useState(0);
  const { t } = useTranslation(['header']);

  useEffect(() => {
    const onScrollNavbarProcessing = () => {
      const newValue = window.pageYOffset;
      const scrolledUp = oldScrollValue > newValue;

      setShowNavbar(scrolledUp);
      setOldScrollValue(newValue);
    };
    window.addEventListener('scroll', onScrollNavbarProcessing);
    return () => window.removeEventListener('scroll', onScrollNavbarProcessing);
  }, [oldScrollValue]);

  return (
    <Center
      zIndex={'overlay'}
      position="fixed"
      width={'full'}
      borderBottom={'1px solid #E1E1E1'}
      transition={'all 0.2s ease-in-out'}
      transform={showNavbar ? 'translateY(0)' : 'translateY(-100%)'}>
      <HStack
        justifyContent={'space-between'}
        bgColor="white"
        h={20}
        flexGrow="1"
        maxW="container.xl"
        px={{ base: 6, md: 4 }}>
        {/* left side of navbar */}
        <HStack spacing={16}>
          <Logo />
          <Show above="md">
            <Heading as="h2" fontSize={{ base: '28px', lg: '32px' }} noOfLines={1}>
              {t('header:title.text')}
            </Heading>
          </Show>
        </HStack>

        {/* right side of navbar */}
        <LanguageSwitch />
      </HStack>
    </Center>
  );
};
