import { ArrowBackIcon } from '@chakra-ui/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { createRef, FC, LegacyRef, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  Heading,
  Input,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Card } from '@components/Card';
import { PurchaseSummary } from '@components/PurchaseSummary';
import {
  BasePackage,
  CountryCode,
  EntityType,
  LanguageCode,
  LanguageFormatLocale,
  Salutation,
} from '@types';
import { useBookingForm } from 'context/formContext/formContext';
import { OrderFormValues } from 'context/formContext/orderForm';
import { usePage } from 'context/pageContext/pageContext';
import { usePrice } from 'context/priceContext/priceContext';
import { useTranslation, Trans } from 'react-i18next';
import { ALERT_TIMEOUT, VAT_AMOUNT } from 'utils/constants/constants';
import { formatNumber } from 'utils/helpers';
import dataLayerHelper, { FormStep } from 'utils/helpers/dataLayer';

/**
 * Renders the "Place Order" - step 4 - view.
 */
export const PlaceOrder: FC = () => {
  const recaptchaRef: LegacyRef<ReCAPTCHA> = createRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n } = useTranslation(['order', 'about', 'others', 'package', 'form']);
  const { isOpen: isVisible, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const { goPrev, goNext } = usePage();
  const { subscribeForm, addonsForm, aboutForm, orderForm } = useBookingForm();
  const { total } = usePrice();
  const currentPackage = subscribeForm.watch('package');
  const selectedLanguage = LanguageFormatLocale[i18n.language as LanguageCode];
  const [showConsentError, setShowConsentError] = useState(false);

  // Update the showConsentError value when the checkbox changes only if it was previously set to true
  const consented = orderForm.watch('hasConsented');
  useEffect(() => {
    if (consented) {
      setShowConsentError(!consented);
    }
  }, [consented]);

  const { handleSubmit, register } = orderForm;
  const euCountries = t('others:countries', {
    returnObjects: true,
  });

  const [
    companyName,
    entityType,
    salutation,
    name,
    surname,
    street,
    streetNo,
    city,
    zip,
    country,
    vatNo,
    email,
    tel,
    dateOfBirth,
  ] = aboutForm.watch([
    'companyName',
    'entityType',
    'salutation',
    'name',
    'surname',
    'street',
    'streetNo',
    'city',
    'zip',
    'country',
    'vatNo',
    'email',
    'tel',
    'dateOfBirth',
  ]);

  const vatAMount = total ? total * VAT_AMOUNT.DE : NaN;
  const totalPriceWithVat = total ? total + vatAMount : NaN;

  const entityTypeOptions = {
    [EntityType.Legal]: t('about:entityType.options.corporation'),
    [EntityType.PartnershipComplementary]: t('about:entityType.options.hybrid'),
    [EntityType.Partnership]: t('about:entityType.options.other'),
    [EntityType.Natural]: t('about:entityType.options.sole'),
  };

  const salutationOptions = {
    [Salutation.Mr]: t('about:personalInformation.options.mr'),
    [Salutation.Ms]: t('about:personalInformation.options.ms'),
    [Salutation.None]: '', // should be empty
  };
  let errorTimeout: NodeJS.Timeout;

  const recaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string;
  const apiUrl = process.env.NEXT_PUBLIC_BOOKING_API_URL as string;

  const onReCAPTCHAChange = async (captchaCode: string | null) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      return;
    }

    setIsSubmitting(true);
    const formValues = {
      ...subscribeForm.getValues(),
      ...addonsForm.getValues(),
      ...aboutForm.getValues(),
      ...orderForm.getValues(),
      company: aboutForm.getValues().companyName,
      pricing: 'monthly',
      sales: Number(subscribeForm.getValues().sales),
      address: `${aboutForm.getValues().street} ${aboutForm.getValues().streetNo}, ${
        aboutForm.getValues().city
      }, ${aboutForm.getValues().country}`,
      language: i18n.language,
    };

    const anonymizedTestId = window.anonymizedTestId;
    try {
      // proxy request via next.config.js
      const response = await fetch(`${apiUrl}/customer`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recaptchaClientKey: captchaCode,
          anonymizedTestId,
          ...formValues,
        }),
        method: 'POST',
      });

      if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      dataLayerHelper.purchase(total.toFixed(2), totalPriceWithVat.toFixed(2));
      dataLayerHelper.checkout(FormStep.Summary);
      goNext();
    } catch (error) {
      //TODO handle error
      console.error('Something went wrong', error);
    } finally {
      // Reset the reCAPTCHA when the request has failed or succeeeded
      // so that it can be executed again if user submits another form.
      recaptchaRef?.current?.reset();
      //TODO reset also state!
      setIsSubmitting(false);
    }
  };
  const onSubmit = (direction: 'forward' | 'backward') => {
    return ({ hasConsented }: OrderFormValues) => {
      if (direction === 'forward') {
        if (!hasConsented) {
          setShowConsentError(true);
          onOpen();
          clearTimeout(errorTimeout);
          errorTimeout = setTimeout(() => {
            onClose();
          }, ALERT_TIMEOUT);
        } else {
          // Execute the reCAPTCHA when the form is submitted
          recaptchaRef?.current?.execute();
        }
      } else {
        goPrev();
      }
    };
  };
  return (
    <>
      {isVisible && (
        <Alert
          status={'error'}
          position={'fixed'}
          transform={'translate(-50%, 0)'}
          top={'0'}
          left={'50%'}
          mt={'24px'}
          zIndex={'9999'}
          bgColor={'#FFE1D7'}
          color={'black'}
          w={{ base: '90%', md: 'auto' }}>
          <AlertIcon />
          <Box>
            <AlertTitle fontSize={'lg'} fontWeight={'semibold'}>
              {t('order:alert.title')}
            </AlertTitle>
            <AlertDescription fontSize={'lg'} fontWeight={'regular'}>
              <li>{t('order:alert.description')}</li>
            </AlertDescription>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={onClose}
          />
        </Alert>
      )}

      <Flex flexDirection="column" alignItems="left" gap={'80px'}>
        <Heading as="h1" my={{ base: '32px', md: '64px' }} fontSize="44px">
          {t('order:headline')}
        </Heading>
        {/* "About you" Section */}
        <Card title={t('order:about.heading')} id="details-summary">
          <Box id="company-Personal-information">
            <Heading fontSize={'2xl'} mb={'24px'}>
              {t('order:about.companyAndPersonalInformation.heading')}
            </Heading>
            <Flex direction={{ base: 'column', md: 'row' }} gap={'24px'} mb={'32px'}>
              <Box w={{ base: '100%', md: '50%' }}>
                <Heading fontSize={'xl'}>
                  {t('order:about.companyAndPersonalInformation.companyName')}
                </Heading>
                <Text>{companyName}</Text>
              </Box>
              <Box w={{ base: '100%', md: '50%' }}>
                <Heading fontSize={'xl'}>
                  {t('order:about.companyAndPersonalInformation.companyType')}
                </Heading>
                <Text>{entityTypeOptions[entityType as EntityType]}</Text>
              </Box>
            </Flex>
            <Flex direction={{ base: 'column', md: 'row' }} gap={'24px'} mb={'32px'}>
              <Box w={{ base: '100%', md: '50%' }}>
                <Heading fontSize={'xl'}>
                  {t('order:about.companyAndPersonalInformation.contactPerson')}
                </Heading>
                <Text>{`${
                  salutationOptions[salutation as Salutation]
                } ${name} ${surname}`}</Text>
              </Box>
              {dateOfBirth && (
                <Box w={{ base: '100%', md: '50%' }}>
                  <Heading fontSize={'xl'}>
                    {t('order:about.companyAndPersonalInformation.birthday')}
                  </Heading>
                  <Text>
                    {new Date(dateOfBirth).toLocaleDateString(selectedLanguage, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
          <Divider borderWidth={'1px'} my={'24px'} />
          <Box id="billing-information">
            <Heading fontSize={'2xl'} mb={'24px'}>
              {t('order:about.billingInformation.heading')}
            </Heading>
            <Flex direction={{ base: 'column', md: 'row' }} gap={'24px'} mb={'32px'}>
              <Box w={{ base: '100%', md: '50%' }}>
                <Heading fontSize={'xl'}>
                  {t('order:about.billingInformation.address')}
                </Heading>
                <Text>{`${street} ${streetNo}`}</Text>
                <Text>{`${zip} ${city}, ${euCountries[country as CountryCode]}`}</Text>
              </Box>
              <Box w={{ base: '100%', md: '50%' }}>
                <Heading fontSize={'xl'}>
                  {t('order:about.billingInformation.vatNumber')}
                </Heading>
                <Text>{vatNo}</Text>
              </Box>
            </Flex>
          </Box>
          <Divider borderWidth={'1px'} my={'24px'} />
          <Box id="contact-information">
            <Heading fontSize={'2xl'} mb={'24px'}>
              {t('order:about.contactInformation.heading')}
            </Heading>
            <Flex direction={{ base: 'column', md: 'row' }} gap={'24px'} mb={'32px'}>
              <Box w={{ base: '100%', md: '50%' }}>
                <Heading fontSize={'xl'}>
                  {t('order:about.contactInformation.email')}
                </Heading>
                <Text>{email}</Text>
              </Box>
              <Box w={{ base: '100%', md: '50%' }}>
                <Heading fontSize={'xl'}>
                  {t('order:about.contactInformation.phone')}
                </Heading>
                <Text>{tel}</Text>
              </Box>
            </Flex>
          </Box>
        </Card>
        {/* "Your Purchase" Section */}
        <Card title={t('order:purchase.heading')} id="purchase-summary" gap={'8px'}>
          <PurchaseSummary />
        </Card>
        {/* "Summary" section */}
        <Flex direction={'column'} gap={'32px'} id={'price-summary'}>
          <Text as={'h2'} fontSize={'4xl'} fontWeight={'600'}>
            {t('order:summary.heading')}
          </Text>
          <Flex direction={'column'} gap={'8px'}>
            <Flex justifyContent={'space-between'}>
              <Text fontSize={'xl'} fontWeight={'semibold'}>
                {t('order:summary.total')}
              </Text>
              <Text
                fontSize={'xl'}
                fontWeight={'semibold'}
                data-testid="order-total-price-without-vat">
                {formatNumber(total || 0, selectedLanguage)} €
              </Text>
            </Flex>
            <Flex justifyContent={'space-between'}>
              <Text fontSize={'lg'}>19% {t('order:summary.vat')}</Text>
              <Text fontSize={'lg'} data-testid="order-vat-price">
                {formatNumber(vatAMount || 0, selectedLanguage)}
                {' €'}
              </Text>
            </Flex>
          </Flex>
          <form>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={recaptchaKey}
              onChange={onReCAPTCHAChange}
            />
            <Flex
              direction={'column'}
              gap={'16px'}
              w={{
                base: '100%',
                md: '50%',
              }}>
              <Text fontSize={'md'} fontWeight={'semibold'}>
                {t('order:summary.coupon.question')}
              </Text>
              <Flex direction={'column'} gap={'4px'}>
                <Input
                  data-testid="order-coupon-code"
                  placeholder={t('order:summary.coupon.placeholder')}
                  {...register('couponCode')}
                />
                <Text fontSize={'sm'} ml={'md'}>
                  {t('order:summary.coupon.note')}
                </Text>
              </Flex>
            </Flex>
            <Flex mt={'64px'} direction={'column'} gap={'16px'}>
              <Checkbox
                data-testid="order-terms-and-conditions-checkbox"
                {...register('hasConsented')}>
                <Text color={showConsentError ? 'red' : 'initial'}>
                  <Trans
                    t={t}
                    i18nKey={'order:termsAndConditions'}
                    components={{
                      1: (
                        <Link
                          fontSize={'md'}
                          color={'taxdooBlue.600'}
                          fontWeight={'semibold'}
                          cursor={'pointer'}
                          lineHeight={'24px'}
                          href="https://static.taxdoo.com/Taxdoo_AGB.pdf"
                          target="_blank"
                        />
                      ),
                      2: (
                        <Link
                          fontSize={'md'}
                          color={'taxdooBlue.600'}
                          fontWeight={'semibold'}
                          cursor={'pointer'}
                          lineHeight={'24px'}
                          href="https://www.taxdoo.com/disclaimer"
                          target="_blank"
                        />
                      ),
                    }}
                  />
                  <Link href="#reference-*">
                    <sup>*</sup>
                  </Link>
                </Text>
              </Checkbox>
              <Divider borderBottom={'2px solid #BEBEBE'} />
            </Flex>
            <Flex my={'24px'} justifyContent={'flex-end'} gap={'4px'}>
              <Flex direction={'column'}>
                <Text
                  fontSize={'4xl'}
                  lineHeight={'40px'}
                  textAlign={'end'}
                  data-testid="order-total-price-with-vat">
                  {formatNumber(totalPriceWithVat || 0, selectedLanguage)}
                  {' €'}
                </Text>
                <Text fontSize={'md'}>
                  {currentPackage === BasePackage.Premium
                    ? t('order:summary.billing.yearlyContract')
                    : t('order:summary.billing.monthlyContract')}
                </Text>
              </Flex>
            </Flex>
            <Flex justifyContent={'flex-end'} gap={'16px'}>
              <Button
                data-testid="order-back-btn"
                fontSize={'lg'}
                lineHeight={'24px'}
                leftIcon={<ArrowBackIcon />}
                variant="link"
                isDisabled={isSubmitting}
                onClick={handleSubmit(onSubmit('backward'))}>
                {t('form:controls.back')}
              </Button>
              <Button
                data-testid="order-submit-btn"
                onClick={handleSubmit(onSubmit('forward'))}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}>
                {t('form:controls.purchaseNow')}
              </Button>
            </Flex>
          </form>
          <Flex
            mt={8}
            mb={16}
            direction={'column'}
            gap={'32px'}
            w={['80%', '70%']}
            display={'flex'}>
            <Text fontSize={'sm'} id="reference-*">
              <sup>*</sup> {t('order:summary.references.*')}
            </Text>
            <Text fontSize={'sm'} id="reference-1">
              <sup>1</sup> {t('order:summary.references.1')}
            </Text>
            {/* currently not displayed */}
            {/* <Text fontSize={'sm'} id="reference-2">
              <sup>2</sup> {t('order:summary.references.2')}
            </Text> */}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
