import { SelectGmv } from '@components/Gmv/SelectGmv';
import { SelectPackage } from '@components/Package';
import { FormProvider } from 'react-hook-form';
import { FC } from 'react';
import { BasePackage } from '@types';
import { useBookingForm } from 'context/formContext/formContext';
import { StickyFooter } from '..';
import { usePage } from 'context/pageContext/pageContext';
import dataLayerHelper, { FormStep } from 'utils/helpers/dataLayer';
import { t } from 'i18next';

export type SubscriptionFormValues = {
  sales?: string;
  package?: BasePackage;
};

export const SubscriptionForm: FC = () => {
  const { goNext } = usePage();
  const { subscribeForm } = useBookingForm();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = subscribeForm;

  const gmvValue = watch('sales');
  const pkgValue = watch('package');

  const goNextPage = () => {
    dataLayerHelper.checkout(FormStep.Subscription);
    goNext();
  };

  return (
    <FormProvider {...subscribeForm}>
      <form>
        {/* Pick your package */}
        <SelectGmv name="sales" control={control} selectedValue={gmvValue} />
        {/* Chose your package */}
        <SelectPackage
          name="package"
          control={control}
          selectedValue={pkgValue}
          selectedGmv={gmvValue}
        />
      </form>

      <StickyFooter
        disableNext={!isValid}
        disableOpen={Boolean(!pkgValue)}
        onNext={handleSubmit(goNextPage)}
        tooltip={t('footer:sticky.tooltip.subscription')}
      />
    </FormProvider>
  );
};
