import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, Button, MenuList, MenuItem } from '@chakra-ui/react';
import { FlagDE } from '@components/Icons/FlagDE';
import { FlagUK } from '@components/Icons/FlagUK';
import { useTranslation } from 'react-i18next';

const getCountryFlag = (countryCode: string): JSX.Element =>
  countryCode === 'en' ? <FlagUK active /> : <FlagDE active />;

export const LanguageSwitch = (): JSX.Element => {
  const { i18n } = useTranslation();

  return (
    <Menu>
      <MenuButton
        background={'#FFFFFF'}
        w={32}
        h={12}
        border="1px solid #BEBEBE"
        color={'black'}
        fontSize={'md'}
        fontWeight={'regular'}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        leftIcon={getCountryFlag(i18n.language)}>
        <div>{i18n.language.toUpperCase()}</div>
      </MenuButton>
      <MenuList>
        <MenuItem icon={<FlagDE active />} onClick={() => i18n.changeLanguage('de')}>
          Deutsch
        </MenuItem>
        <MenuItem icon={<FlagUK active />} onClick={() => i18n.changeLanguage('en')}>
          English
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
