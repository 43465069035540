import { Box, Flex, FlexProps, Heading } from '@chakra-ui/react';

type CardProps = FlexProps & {
  title: string;

  children?: React.ReactNode;
};
/**
 * This component renders a card with a title
 */
export const Card = ({ title, children, ...props }: CardProps): JSX.Element => {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={'32px'}
      p={'40px 24px'}
      boxShadow={
        '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.08)'
      }
      borderRadius={'12px'}
      {...{ ...props }}>
      <Heading as="h2" fontSize="4xl" alignSelf="start" mb="32px">
        {title}
      </Heading>
      <Box width="100%">{children}</Box>
    </Flex>
  );
};
