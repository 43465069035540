import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon, CheckIcon } from '@chakra-ui/icons';
import { FC } from 'react';

export interface PackageDetail {
  text: string;
  tooltip?: string;
}

interface PackageProps {
  name: string;
  value: string;
  price: string;
  priceDetail: string;
  features: PackageDetail[];
  ctaBtnText: string;
  isDisabled: boolean;
  isChecked: boolean;
  children?: React.ReactNode;
}

export const Package: FC<PackageProps> = ({
  name,
  price,
  priceDetail = '',
  features = [],
  ctaBtnText,
  isChecked,
  isDisabled,
  children,
}) => (
  <Flex
    cursor={'pointer'}
    borderRadius={'12px'}
    overflow={'hidden'}
    flexDirection={'column'}
    w={'100%'}
    boxShadow={'md'}
    outline={isChecked ? '2px solid' : 'none'}
    outlineOffset={'-2px'}
    outlineColor={isChecked ? 'taxdooBlue.900' : 'none'}
    _hover={{ boxShadow: 'lg' }}>
    <Flex
      flexDirection={'column'}
      p={'24px'}
      backgroundColor={isDisabled ? 'grey.200' : 'taxdooBlue.50'}>
      <Flex alignItems={'center'} pb={'24px'}>
        <Text
          fontSize={'2xl'}
          lineHeight={'32px'}
          fontWeight={'semibold'}
          w={'100%'}
          pr={'8px'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          noOfLines={1}
          color={isDisabled ? 'grey.400' : 'taxdooBlue.900'}>
          {name}
        </Text>
        <div>{children}</div>
      </Flex>
      <Flex minH={'86px'} justifyContent={'center'} flexDirection={'column'}>
        <Text
          color={isDisabled ? 'grey.400' : 'taxdooBlue.900'}
          fontSize={'44px'}
          lineHeight={'48px'}
          fontWeight={700}>
          {price}
        </Text>

        {priceDetail && (
          <Text
            mt="18px"
            fontSize={'sm'}
            lineHeight={'20px'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            noOfLines={3}
            color={isDisabled ? 'grey.400' : 'taxdooBlue.900'}>
            {priceDetail}
          </Text>
        )}
      </Flex>
    </Flex>
    <Flex flexDirection={'column'} h={'100%'} p={'24px'}>
      <Flex mb={'32px'} flexDirection={'column'}>
        {features.map((feature) => (
          <Flex key={feature.text} alignItems={'center'} mb={'16px'}>
            <CheckIcon
              color={isDisabled ? 'grey.400' : 'taxdooBlue.600'}
              alignSelf={'flex-start'}
              mt={'8px'}
            />
            <Text
              pr={'10px'}
              pl={'12px'}
              fontSize={'lg'}
              lineHeight={'28px'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              noOfLines={2}
              color={isDisabled ? 'grey.400' : 'black'}>
              {feature.text}
            </Text>
            {feature.tooltip && (
              <Tooltip hasArrow label={feature.tooltip} p={4} backgroundColor="info.600">
                <InfoOutlineIcon
                  ml={'auto'}
                  color={isDisabled ? 'grey.400' : 'gray.500'}
                />
              </Tooltip>
            )}
          </Flex>
        ))}
      </Flex>
      <Flex
        px={'24px'}
        py={'12px'}
        borderRadius={'lg'}
        mt={'auto'}
        justifyContent={'center'}
        backgroundColor={
          isDisabled ? 'grey.100' : isChecked ? 'taxdooBlue.900' : 'taxdooBlue.600'
        }
        cursor={isDisabled ? 'not-allowed' : 'pointer'}>
        <Text
          color={isDisabled ? 'grey.400' : 'white'}
          fontWeight={'semibold'}
          fontSize={'lg'}
          lineHeight={'24px'}>
          {ctaBtnText}
        </Text>
      </Flex>
    </Flex>
  </Flex>
);
