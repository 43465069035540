import * as React from 'react';
import { Box, Text } from '@chakra-ui/react';
import FlyOut from './Flyout';
import { useTranslation } from 'react-i18next';

const FlyoutMenu = () => {
  const { t } = useTranslation(['flyout']);
  const phoneNumber = t('flyout:phoneNumber');

  return (
    <FlyOut
      top="220px"
      mainBodyWidth="375px"
      mainBodyHeight="332px"
      buttonWidth="88px"
      buttonHeight="72px"
      buttonLabel={t('flyout:buttonText')}>
      <Text
        fontWeight="semibold"
        fontSize="4xl"
        lineHeight="40px"
        color="#0F0F0F"
        marginBottom="20px">
        {t('flyout:header')}
      </Text>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap="20px">
        <Text fontWeight="regular" fontSize="xl" lineHeight="28px" color="#0F0F0F">
          {t('flyout:text')}
        </Text>
        <Text fontWeight="semibold" fontSize="4xl" lineHeight="40px" color="#0D90CE">
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </Text>
        <Text fontWeight="regular" fontSize="xl" lineHeight="28px" color="#0F0F0F">
          {t('flyout:footerText')}
        </Text>
      </Box>
    </FlyOut>
  );
};

export default FlyoutMenu;
